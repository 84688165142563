export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "asset-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
      "free-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
      "spot-borrow-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot borrow"])},
      "available-without-borrow-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available without borrow"])},
      "total-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "usd-value-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD converted"])}
    }
  })
}
