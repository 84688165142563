export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "counterparty-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterparty"])},
      "hash-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx hash"])},
      "coin-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "time-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "amount-cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
    }
  })
}
