
import { callers, extractApiError } from '@/api'
import { WalletHistoryItemResponse } from '@/api/apiTypes'
import { useSubmitter } from '@/composables/useSubmitter'
import { defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import WalletHistoryHead from '../../components/WalletHistoryHead.vue'
import WalletHistoryItem from '../../components/WalletHistoryItem.vue'
import LoadMoreButton from '@/components/LoadMoreButton.vue'

export default defineComponent({
  components: { WalletHistoryItem, WalletHistoryHead },
  setup() {
    const error = ref<null | string>(null)
    const list = ref<null | WalletHistoryItemResponse[]>(null)
    const filters = reactive({ market: '' })

    const [_load, isLoading] = useSubmitter({
      request: () => callers.getWithdrawals({}),
      onSuccess: ({ data }) => (list.value = data),
      onError: (err) => {
        error.value = extractApiError(err)
      },
    })

    const [loadMore, isLoadingMore] = useSubmitter({
      request: () =>
        callers.getWithdrawals({
        }),
      onSuccess: ({ data }) => {
        if (!list.value) list.value = data
        else list.value = list.value.concat(data)
      },
    })

    _load()

    return {
      isLoading,
      loadMore,
      isLoadingMore,
      error,
      list,
      filters,
      t: useI18n().t,
    }
  },
})
