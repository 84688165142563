import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "balances container" }
const _hoisted_2 = { class: "app-body-header" }
const _hoisted_3 = { class: "app-block-content p-10" }
const _hoisted_4 = {
  key: 0,
  class: "min-w-max overflow-x-auto"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center text-base p-4"
}
const _hoisted_6 = {
  key: 2,
  class: "text-center text-base p-4"
}
const _hoisted_7 = {
  key: 3,
  class: "text-center text-base p-4 text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalancesHead = _resolveComponent("BalancesHead")!
  const _component_BalancesItem = _resolveComponent("BalancesItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('balances-header')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.list?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BalancesHead),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
              return (_openBlock(), _createBlock(_component_BalancesItem, {
                key: item.id,
                item: item
              }, null, 8, ["item"]))
            }), 128))
          ]))
        : (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.t('loading-txt')), 1))
          : (!_ctx.error)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t('no-items-txt')), 1))
            : _createCommentVNode("", true),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}