import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d5ada5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wallet-history-head grid grid-flow-col gap-x-2 auto-cols-fr app-table-headers-wrap min-w-row" }
const _hoisted_2 = { class: "wallet-history-head__cell" }
const _hoisted_3 = { class: "wallet-history-head__cell" }
const _hoisted_4 = { class: "wallet-history-head__cell" }
const _hoisted_5 = { class: "wallet-history-head__cell" }
const _hoisted_6 = { class: "wallet-history-head__cell text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('hash-cell')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('counterparty-cell')), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('time-cell')), 1),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('coin-cell')), 1),
    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('amount-cell')), 1)
  ]))
}