import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034320e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "balances-item min-w-row" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "balances-item__cell",
      title: _ctx.item.id
    }, _toDisplayString(_ctx.item.id), 9, _hoisted_2),
    _createElementVNode("span", {
      class: "balances-item__cell text-right",
      title: _ctx.item.total
    }, _toDisplayString(_ctx.formatNumber(_ctx.item.amount)), 9, _hoisted_3)
  ]))
}