export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en-US",
    "resource": {
      "balances-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balances"])},
      "loading-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading…"])},
      "no-items-txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active balances found"])}
    }
  })
}
