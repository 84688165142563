import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2108fa30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "balances-head grid grid-flow-col gap-x-2 auto-cols-fr app-table-headers-wrap min-w-row" }
const _hoisted_2 = { class: "balances-head__cell" }
const _hoisted_3 = { class: "balances-head__cell text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('asset-cell')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('total-cell')), 1)
  ]))
}