import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4220f05d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wallet-history-item px-12 min-w-row" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "wallet-history-item__cell",
      title: _ctx.item.hash
    }, [
      _createElementVNode("a", {
        href: _ctx.item.explorer_tx_url,
        target: "_blank"
      }, _toDisplayString(_ctx.item.hash), 9, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("span", {
      class: "wallet-history-item__cell",
      title: _ctx.item.counterparty
    }, [
      _createElementVNode("a", {
        href: _ctx.item.explorer_address_url,
        target: "_blank"
      }, _toDisplayString(_ctx.item.counterparty), 9, _hoisted_5)
    ], 8, _hoisted_4),
    _createElementVNode("span", {
      class: "wallet-history-item__cell",
      title: _ctx.formatDate(_ctx.item.created_at, _ctx.DateFormatPresets.full)
    }, _toDisplayString(_ctx.formatDate(_ctx.item.created_at)), 9, _hoisted_6),
    _createElementVNode("span", {
      class: "wallet-history-item__cell",
      title: _ctx.item.asset
    }, _toDisplayString(_ctx.item.asset), 9, _hoisted_7),
    _createElementVNode("span", {
      class: "wallet-history-item__cell text-right",
      title: _ctx.item.amount
    }, _toDisplayString(_ctx.formatNumber(_ctx.item.amount)), 9, _hoisted_8)
  ]))
}