
import { callers, extractApiError } from '@/api'
import { BalanceResponse } from '@/api/apiTypes'
import { useSubmitter } from '@/composables/useSubmitter'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BalancesHead from './BalancesHead.vue'
import BalancesItem from './BalancesItem.vue'

export default defineComponent({
  components: { BalancesHead, BalancesItem },
  setup() {
    const error = ref<null | string>(null)
    const list = ref<null | BalanceResponse[]>(null)

    const [_load, isLoading] = useSubmitter({
      request: () => callers.getBalances(),
      onSuccess: ({ data }) => (list.value = data),
      onError: (err) => {
        error.value = extractApiError(err)
      },
    })

    _load()

    return {
      isLoading,
      error,
      list,
      t: useI18n().t,
    }
  },
})
