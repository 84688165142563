import { TimestampIso8601 } from '@/types/common'
import formatter from 'date-fns/format'

export enum DateFormatPresets {
  default = 'default',
  noYearShorterMonth = 'noYearShorterMonth',
  full = 'full',
}

export function formatDate(
  date: Date | TimestampIso8601,
  preset = DateFormatPresets.default
): string {
  if (typeof date === 'string') date = new Date(date)
  return formatter(date, _formatMapper(preset))
}

function _formatMapper(preset: DateFormatPresets): string {
  switch (preset) {
    case DateFormatPresets.default:
    case DateFormatPresets.noYearShorterMonth:
      return 'HH:mm:ss, dd MMM'
    case DateFormatPresets.full:
      return 'HH:mm:ss, dd MMMM yyyy'
    default:
      console.warn(`No format found for ${preset}, falling back to default`)
      return _formatMapper(DateFormatPresets.default)
  }
}
