import { StringAmount } from '@/types/common'

export function formatNumber(
  stringAmount: StringAmount | number | string
): string {
  const num = Number(stringAmount)
  if (Number.isNaN(num)) return Number(0).toFixed(6)
  const nums = num.toFixed(6).split('.')
  nums[0] = nums[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return nums.join('.')
}
