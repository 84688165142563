
import { defineComponent, PropType } from 'vue'
import { BalanceResponse } from '@/api/apiTypes'
import { formatNumber } from '@/helpers/formatters/formatNumber'

export default defineComponent({
  props: { item: Object as PropType<BalanceResponse> },
  setup() {
    return {
      formatNumber,
    }
  },
})
