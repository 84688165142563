
import { defineComponent, PropType } from 'vue'
import { WalletHistoryItemResponse } from '@/api/apiTypes'
import { formatNumber } from '@/helpers/formatters/formatNumber'
import { DateFormatPresets, formatDate } from '@/helpers/formatters/formatDate'
import { translateOrderSide } from '@/helpers/translators/translateOrderSide'

export default defineComponent({
  props: { item: Object as PropType<WalletHistoryItemResponse> },
  setup() {
    return {
      DateFormatPresets,
      formatNumber,
      formatDate,
      translateOrderSide,
    }
  },
})
