import { t } from '@/i18n'
import { OrderSide } from '@/api/apiEnums'

export function translateOrderSide(orderSide: OrderSide): string {
  switch (orderSide) {
    case OrderSide.buy:
      return t('order-sides.buy')
    case OrderSide.sell:
      return t('order-sides.sell')
    default:
      return orderSide
  }
}
